.pricing-header {
    max-width: 700px;
}

.bg-lightgrey {
    background-color: #DDD !important;
}

.bg-lightgrey2 {
    background-color: #BBB !important;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }