@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

html {
  margin: 0;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-lightpink {
  background-color: #eb8475;
}

.btn-blue {
  background-color: #3539b7 !important;
  border-color: #3539b7 !important;
  outline-color: #3539b7 !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.btn-grey {
  background-color: #999 !important;
  border-color: #999 !important;
  outline-color: #999 !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.text-blue {
  color: #3539b7 !important;
}

.form-title {
  margin-bottom: 25px;
}

.textbox-blue {
  border-color: #3539b7 !important;
  outline-color: #3539b7 !important;
}

.form-wrapper {
  background-color: #ccc;
  border-radius: 10px;
}

.text-darkblue {
  color: #3539b7 !important;
}

.my-table {
  text-align: left;
  text-indent: 0;
}

.my-table td {
  padding: 0;
}

.my-table td b {
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
